import { Outlet } from "react-router-dom";
import { Navigation } from "../components/Navigation";
import { Footer } from "../components/Footer";
import ScrollToTop from "../hooks/ScrollToTop";
import moment from "moment";

export const Layout = () => {
  const websiteIsActive = () => {
    // const now = moment();
    // const opening = moment("2024-08-28T05:00:00+10:00");

    // return now.isAfter(opening);

    return true;
  };

  return websiteIsActive() ? (
    <div>
      <ScrollToTop />

      <Navigation />

      <Outlet />

      <Footer />
    </div>
  ) : (
    <div class="flex flex-col items-center justify-center h-screen w-screen">
      <div className="text-3xl font-bold">
        Weco Polymer Fusion, Coming Soon...
      </div>
      <img
        src="https://wpfusion.com.au/under-construction.jpg"
        className="h-1/2"
      />
    </div>
  );
};
