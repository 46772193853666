import SM160 from "../assets/products/straight-pipes/M160.png";
import S160 from "../assets/products/straight-pipes/160.png";
import S250 from "../assets/products/straight-pipes/250.png";
import S315 from "../assets/products/straight-pipes/315.png";
import S450 from "../assets/products/straight-pipes/450.png";
import S630 from "../assets/products/straight-pipes/630.png";
import S800 from "../assets/products/straight-pipes/800.png";
import S1000 from "../assets/products/straight-pipes/1000.jpg";
import S1200 from "../assets/products/straight-pipes/1200.png";
import S1600 from "../assets/products/straight-pipes/1600.png";
import S2000 from "../assets/products/straight-pipes/2000.png";

import C315 from "../assets/products/pipe-cutting/315.jpg";
import C630 from "../assets/products/pipe-cutting/630.png";
import C800 from "../assets/products/pipe-cutting/800.jpg";
import C1200 from "../assets/products/pipe-cutting/1200.jpg";
import C1600 from "../assets/products/pipe-cutting/1600.jpg";

import SM630 from "../assets/products/fitting-fabrication/saddle-machines/630.jpg";
import SM1200 from "../assets/products/fitting-fabrication/saddle-machines/1200.jpg";

import CTY315 from "../assets/products/fitting-fabrication/cty/315.png";
import CTY450 from "../assets/products/fitting-fabrication/cty/450.jpg";
import CTY630 from "../assets/products/fitting-fabrication/cty/630.png";
import CTY800 from "../assets/products/fitting-fabrication/cty/800.png";
import CTY1000 from "../assets/products/fitting-fabrication/cty/1000.jpg";
import CTY1200 from "../assets/products/fitting-fabrication/cty/1200.jpg";
import CTY1600 from "../assets/products/straight-pipes/1600.jpg";
import CTY2000 from "../assets/products/fitting-fabrication/cty/2000.jpg";

export const PRODUCT_SURVEY = {
  question: "What type of machine are you looking for?",
  options: [
    {
      name: "Straight Pipes",
      products: [
        {
          name: "WPFM160",
          image: SM160,
          description:
            "This manually operated butt-welding machine is designed for welding PE and PP pipes and fittings with diameters ranging from 40mm to 160mm OD. Its efficient design and construction make it an excellent low-cost option for both worksite and factory applications. The machine features high-quality aluminium castings, reducing weight without sacrificing strength or performance. Additionally, an optional torque wrench is available, allowing for precise measurement of welding force.",
        },
        {
          name: "WPF160",
          image: S160,
          description:
            "This is a hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 50mm to 160mm OD, using low-force welding methods. Its high-quality, pressure-cast aluminium castings ensure a lightweight design without compromising strength and performance, offering additional strength when welding out-of-round pipes. CE approved by ECM standards.",
        },
        {
          name: "WPF250",
          image: S250,
          description:
            "The Weco Polymer 250 is a hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 50mm to 250mm OD. Its excellent design and construction make it a premium choice for both worksite and factory welding applications. The machine features high-quality, pressure-cast aluminium castings, which ensure a lighter weight without compromising strength or performance. This also provides added strength when welding out-of-round pipes. The machine offers flexibility, allowing welding between clamps 3 and 4, making it ideal for tees, bends, and manifold work.",
        },
        {
          name: "WPF315",
          image: S315,
          description:
            "The Weco Polymer 315 is a hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 90mm to 315mm OD. This versatile machine allows for butt welding between clamps 3 and 4, making it ideal for tees, bends, and manifold work. It can also be seamlessly integrated with an optional data logger for enhanced performance monitoring. CE approved by ECM standards.",
        },
        {
          name: "WPF450",
          image: S450,
          description:
            "The Weco Polymer 450 is a hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 200mm to 450mm OD. With an available modification, it can also weld pipes down to 160mm in SDR 9 and 11. This premium machine is suitable for both worksite and factory welding applications, offering excellent design and construction. CE approved by ECM standards.",
        },
        {
          name: "WPF630",
          image: S630,
          description:
            "The Weco Polymer 630 is a hydraulically operated butt-welding machine designed for HDPE and PP pipes and fittings with diameters ranging from 315mm to 630mm (12&quot; to 24&quot;). It supports welding in accordance with ISO/DVS/POP003 standards for both SLP and DLP low-force methods, making it a versatile and reliable choice for both worksite and factory applications. For enhanced functionality, the Weco Polymer 630 can be seamlessly integrated with an optional data logger and overhead electric crane. CE approved by ECM standards.",
        },
        {
          name: "WPF800",
          image: S800,
          description:
            "Weco Polymer 800 LF hydraulically operated butt-welding machine suitable for PE and PP pipes and fittings from 450 (optionally 400) to 800mm OD and 18&quot; to 30&quot; IPS. Excellent design and construction provide a premium machine for welding both on the worksite and in the factory. Can be integrated with optional data logger and overhead electric crane. CE approved by ECM standards.",
        },
        {
          name: "WPF1000",
          image: S1000,
          description:
            "Weco Polymer 1000 hydraulically operated butt-welding machine suitable for PE and PP pipes and fittings from 500 to 1000mm or 36&quot; DIPS to 20&quot; OD. Excellent design and construction provide a premium machine for welding both on the worksite and in the factory. Welding can be carried out between clamps 3 &amp; 4 for tees, bends and manifold work, and the machine is easily integrated with optional data logger and overhead electric crane. CE approved by ECM standards.",
        },
        {
          name: "WPF1200",
          image: S1200,
          description:
            "Weco Polymer 1200/48 hydraulically operated butt-welding machine, suitable for PE and PP pipes and fittings from 630 to 1200mm OD, alternatively 24 to 48&quot;. Welding can be carried out between clamps 3 &amp; 4 for tees, bends and manifold work. Can be integrated with optional data logger and overhead electric crane. CE approved by ECM standards.",
        },
        {
          name: "WPF1600",
          image: S1600,
          description:
            "The Weco Polymer 1600 is a CNC-controlled, hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 900mm to 1600mm OD. This advanced machine is the culmination of over 60 years of direct involvement in the PE pipe industry, offering premium equipment for both worksite and factory applications. Weco Polymer Fusion1600 delivers a lightweight design without compromising on strength and performance. The machine is operated via a portable wired console, flexible portable touch screen operation. Key features include high-quality hinged steel main clamps with hydraulic open/close and locking mechanisms, providing secure and reliable operation. The machine also comes equipped with a Remote Monitoring System for online assistance, PLC control of temperature, time, and pressure, and onboard data logging capabilities. Additionally, the Weco Polymer 1600 offers the flexibility to weld between clamps 2 &amp; 3 or 3 &amp; 4, making it an exceptionally versatile tool for complex welding tasks. The Weco Polymer 1600 is a CNC-controlled, hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 900mm to 1600mm OD. CE approved by ECM standards.",
        },
        {
          name: "WPF2000",
          image: S2000,
          description:
            "The Weco Polymer 2000 Hydra LF is a hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 1400mm to 2000mm OD. It features high-quality hinged steel main clamps with hydraulic open/close and locking functions, providing secure and reliable performance. The machine also includes a Remote Monitoring System for online assistance, PLC control for temperature, time, and pressure, and onboard data logging. CE approved by ECM standards.",
        },
      ],
    },
    {
      name: "Saddle Machines",
      products: [
        {
          name: "WPFSS630",
          image:
            "https://t4.ftcdn.net/jpg/04/99/10/95/360_F_499109533_eSJkDKEilqy5EDyE8uJUBool0Ex5A34R.jpg",
          description:
            'Hydraulically operated saddle fusion machine for the production of 90-degree reducing tees from standard PE pipe sections. Capacity 315 to 630mm OD (12" to 24") main pipe / 63 to 315mm (2" to 12") branch pipe, where branch pipe must not exceed 50% of the main diameter. Machine is constructed to allow operation by one person, incorporating main pipe clamp, branch pipe clamps, hydraulically removed heating plate with curved elements and integrated hole saw for final removal of pipe coupon.',
        },
        {
          name: "WPFSS1200",
          image:
            "https://t4.ftcdn.net/jpg/04/99/10/95/360_F_499109533_eSJkDKEilqy5EDyE8uJUBool0Ex5A34R.jpg",
          description:
            'Hydraulically operated saddle fusion machine for the production of 90-degree reducing tees from standard PE pipe sections. Capacity 315 to 630mm OD (12" to 24") main pipe / 63 to 315mm (2" to 12") branch pipe, where branch pipe must not exceed 50% of the main diameter. Machine is constructed to allow operation by one person, incorporating main pipe clamp, branch pipe clamps, hydraulically removed heating plate with curved elements and integrated hole saw for final removal of pipe coupon.',
        },
      ],
    },
    {
      name: "Crosses, Tees & Ys",
      products: [
        {
          name: "WPFW315",
          image: CTY315,
          description:
            "Hydraulically operated workshop machine for the butt-welding of pipes and fittings in PE, PP and other thermoplastic materials. Pivoting clamps allow for the fabrication of segmented bends of different radii. Optional clamps allow the production of bends from 90 to 315mm OD, 90 to 315mm 90 degree equal tees and crosses, and 45 and 60 degree branch tees 90mm to 315mm. Includes pressure transducer and wiring ready to immediately accept optional data logger.",
        },
        {
          name: "WPFW450",
          image: CTY450,
          description:
            "Hydraulically operated workshop machine for the production of butt welded fittings from PE & PP pipe. CNC operation provides for correct welding parameters, and includes an integrated 10000+ weld data logger. Optional pivoting clamps allow for the fabrication of segmented bends of different radii. Included as standard with this machine are all clamps required for the production of bends. Optional clamps allow production of bends from 160mm to 450mm OD, 90 degree equal tees and crosses 160mm to 450mm, and 45 and 60 degree equal branch tees 160mm to 450mm. CNC touch-screen control of welding operations and integral data logger are standard.",
        },
        {
          name: "WPFW630",
          image: CTY630,
          description:
            'Hydraulically operated workshop machine for the butt-welding of pipes and fittings in PE, PP and other thermoplastic materials. Optional pivoting clamps allow for the fabrication of segmented bends of different radii from 315 to 630mm OD, 315 to 630mm OD" 90 degree equal tees and crosses, and 45 and 60 degree equal branch tees 315 to 630mm. Touch-screen with CNC control of welding cycle and 10,000+ weld data logging is standard.',
        },
        {
          name: "WPFW800",
          image: CTY800,
          description:
            "Hydraulically operated workshop machine for the butt-welding of pipes and fittings in HDPE and PP. CNC operation provides correct parameters for every weld. Pivoting clamps allow for the fabrication of segmented bends of different radii. Optional clamps for this machine include those required for the production of bends from 450mm to 800mm OD, 90 degree equal tees and crosses 450mm to 800mm , and 45 and 60 degree branch tees 450mm to 800mm. CNC touch-screen control of welding cycle is standard.",
        },
        {
          name: "WPFW1000",
          image: CTY1000,
          description:
            "Hydraulically operated workshop machine for the butt-welding of pipes and fittings in PE, PP and other thermoplastic materials. CNC operation provides correct welding parameters, while on-board data logger is ideal for quality control. Pivoting clamps allow for the fabrication of segmented bends of different radii. Optional clamps allow the production of bends from 500 to 1000mm OD, 500 to 1000mm 90 degree equal tees and crosses, and 45 and 60 degree branch tees 500mm to 1000mm. Welding is performed at low-force.",
        },
        {
          name: "WPFW1200",
          image: CTY1200,
          description:
            "Hydraulically operated workshop machine for the butt-welding of pipes and fittings in HDPE and PP. CNC operation provides correct welding parameters, while on-board data logger saves quality control information. Optional pivoting clamps allow for the fabrication of segmented bends of different radii. Optional clamps allow the production of bends from 630 to 1200mm OD, 90 degree tees and crosses from 630 to 1200, and 45 & 90 degree branch tees from 630 to 1200mm. Please note that unlike others, this machine produces ALL fittings in ALL diameters within the range. Welding is performed and variable force.",
        },
        {
          name: "WPFW1600",
          image: CTY1600,
          description:
            "Hydraulically operated workshop machine for the butt welding of pipes and fittings in PE, PP and other thermoplastic materials. CNC touch-screen control provides correct welding parameters, while on-board data logger saves quality control information. With optional clamps, machine capacity is 1000mm to 1600mm OD bends of varying radii, 1000mm to 1600mm equal tees and crosses, and 1000mm to 1600mm 45 and 60 degree equal branch tees and true-wyes. Please note that unlike many, this machine is capable of producing the entire range of fittings across all diameters.",
        },
        {
          name: "WPFW2000",
          image: CTY2000,
          description:
            "Hydraulically operated workshop machine for the butt welding of pipes and fittings in HDPE. CNC control provides correct welding parameters, while on-board data logger saves quality control information. optional clamps allow for the fabrication of segmented bends of different radii, equal square tees and crosses, equal y-branch tees of 45 and 60 degrees. Capacity 1200mm to 2000mm OD bends, 1200mm to 2000mm equal tees and crosses, and 1200mm to 2000mm 45 and 60 degree equal wye-branch tees. Welding is carried out at variable interface force. Please note that unlike many, this machine is capable of producing the entire range of fittings across all diameters.",
        },
      ],
    },
    {
      name: "Pipe Saw",
      products: [
        {
          name: "WPFS315",
          image: C315,
          description:
            'Hydraulically operated pipe saw specifically produced for the preparation of pipe segments in PE, PP and other thermoplastic materials, used for the production of bends, tees and other fabrications. Capacity 90 to 630mm OD / 3" to 24" IPS. Cutting angle is -10 to +45 degrees. Lowering rate of saw is hydraulically controlled as are the strap clamps used to hold pipe segments in place. Saw blade made in Germany.',
        },
        {
          name: "WPFS630",
          image: C630,
          description:
            'Hydraulically operated pipe saw specifically produced for the preparation of pipe segments in PE, PP and other thermoplastic materials, used for the production of bends, tees and other fabrications. Capacity 90 to 630mm OD / 3" to 24" IPS. Cutting angle is -10 to +45 degrees. Lowering rate of saw is hydraulically controlled as are the strap clamps used to hold pipe segments in place. Saw blade made in Germany.',
        },
        {
          name: "WPFS800",
          image: C800,
          description:
            'Hydraulically operated pipe saw specifically produced for the preparation of pipe segments in PE, PP and other thermoplastic materials, used for the production of bends, tees and other fabrications. Capacity 90 to 630mm OD / 3" to 24" IPS. Cutting angle is -10 to +45 degrees. Lowering rate of saw is hydraulically controlled as are the strap clamps used to hold pipe segments in place. Saw blade made in Germany.',
        },
        {
          name: "WPFS1200",
          image: C1200,
          description:
            'Hydraulically operated pipe saw specifically produced for the preparation of pipe segments in PE, PP and other thermoplastic materials, used for the production of bends, tees and other fabrications. Capacity 90 to 630mm OD / 3" to 24" IPS. Cutting angle is -10 to +45 degrees. Lowering rate of saw is hydraulically controlled as are the strap clamps used to hold pipe segments in place. Saw blade made in Germany.',
        },
        {
          name: "WPFS1600",
          image: C1600,
          description:
            'Hydraulically operated pipe saw specifically produced for the preparation of pipe segments in PE, PP and other thermoplastic materials, used for the production of bends, tees and other fabrications. Capacity 90 to 630mm OD / 3" to 24" IPS. Cutting angle is -10 to +45 degrees. Lowering rate of saw is hydraulically controlled as are the strap clamps used to hold pipe segments in place. Saw blade made in Germany.',
        },
      ],
    },
  ],
};
